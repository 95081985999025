/*
 * Copyright
 */
/*
 * User: sunny
 * Date: 7/22/21
 * Time: 12:02
 */

import React from 'react';

function Index(props) {
  return (
    <div>Smile everyday!</div>
  );
}

export default Index;
